import { API } from 'aws-amplify'
import { handleError } from './error'

const updateEmarsysContact = async (
  email: string,
  firstName: string,
  lastName: string,
  mobile: string,
  gender: string,
  address: string,
  marketingOptIn: boolean
) => {
  await API.post('clickfrenzyFeEmarsys', '/contact', {
    body: {
      email,
      gender,
      address,
      given_name: firstName,
      family_name: lastName,
      phone_number: mobile,
      'custom:marketing_opt_in': marketingOptIn,
    },
  })
}

const addUserToFwBEmarsys = async (username: string, email: string, answer: boolean): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    API.post('clickfrenzyFeEmarsys', '/join', {
      body: {
        username,
        email,
        answer,
      },
    }).then(res => {
      if (res.success) {
        resolve(true)
      } else {
        handleError(res.error)
        reject(false)
      }
    })
  })
}

// const setEmarsysWebExtend = (email?: string, command?: string, value?: any, go?: boolean) => {
//     if (go) {
//         (window as any).ScarabQueue.push(['go'])
//     } else {
//         if (command && value) {
//             (window as any).ScarabQueue.push([command, value])
//         } else {
//             if (email) {
//                 (window as any).ScarabQueue.push(['setEmail', email])
//             }
//         }
//     }
// }

export {
  updateEmarsysContact,
  addUserToFwBEmarsys,
  // setEmarsysWebExtend,
}
