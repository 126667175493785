import * as React from 'react'
import EventType from '../../../models/eventType'
import { CfButton } from '.'
import './DateButton.scss'

type Props = {
  id?: string
  eventType?: EventType
  className?: string
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
}

class DateButton extends React.Component<React.PropsWithChildren<Props>> {
  render() {
    const { className, children, utmSource = 'website', utmMedium = 'homepage', utmCampaign = 'main' } = this.props

    // const baseUrl = '//clickfrenzy.roktcalendar.com/click-frenzy/subscription/subscribe'
    // const link = `${baseUrl}&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`
    // const clickHandler = (e: React.MouseEvent) => e.preventDefault()
    const baseUrl = '/account?join'
    const link = `${baseUrl}&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`

    return (
      <div className='date-btn-wrap'>
        <CfButton
          // id={id}
          className={`flex-row align-center gap-xxl ${className}`}
          size='large'
          buttonType='cfwhiteoutline'
          redirectUrl={link}
          // dataAttributes={{
          //   'data-popup': 'lightbox',
          //   'data-type': 'custombutton',
          //   'data-calreply-binding': 'true',
          // }}
          // onClick={clickHandler}
        >
          {children}
        </CfButton>
      </div>
    )
  }
}

export { DateButton }
