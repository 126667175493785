import { CollectionBannerProps } from '../../models/collectionBanner'
import './CollectionBanner.scss'

const CollectionBanner = ({ target, desktopImage, mobileImage, alt }: CollectionBannerProps) => {
  const mobileImageUrl = mobileImage || desktopImage

  // add https:// to url if it doesn't exist
  const linkUrl = target && /^http?s:\/\//.test(target) ? target : `https://${target}`

  if (!desktopImage && !mobileImage) {
    return null
  }

  return (
    <div className='collection-banner'>
      <div className='collection-banner__image'>
        {linkUrl !== undefined && (
          <a
            href={linkUrl}
            className='collection-link'
          >
            <picture>
              <source
                srcSet={`${desktopImage}`}
                media='(min-width: 768px)'
              />
              <img
                src={`${mobileImageUrl}`}
                {...(alt && { alt: alt })}
              />
            </picture>
          </a>
        )}
      </div>
    </div>
  )
}

export default CollectionBanner
