import * as React from 'react'
import Collection from '../../deal/Collection'
import { default as EventCollection } from '../../../models/collection'
import { BannerAd, BannerCarouselWithData, Section, CtaBanner } from '../../../components'
import { Layout } from '../../layout'
import { HeaderWithData } from './header'
import { FeaturedBrandsPanel } from '../../brand/featured-brands'
// import { MembershipBenefitsForCurrentEventWithData } from '../../../components/threeColumnImageText/MembershipBenefitsForCurrentEventWithData'
import Partners from '../../../components/partners/Partners'
import { FooterVariation } from '../../global/footer/Footer'
import EventType from '../../../models/eventType'

type Props = {
  collections: EventCollection[] | undefined
  eventType?: EventType
  variation?: FooterVariation
}

class Home extends React.Component<Props> {
  render() {
    const { collections, variation = 'under-full-width', eventType } = this.props
    let firstCollection
    let remainingCollections

    if (collections) {
      let sortedCollections = collections.sort((a, b) => Number(a.sortOrder) - Number(b.sortOrder))
      // Filter out Pre Sale collection
      sortedCollections = sortedCollections.filter(collection => collection.slug !== 'pre-sale')
      firstCollection = sortedCollections.length > 0 ? sortedCollections[0] : null
      remainingCollections = sortedCollections.length > 1 ? sortedCollections.slice(1) : null
    }

    return (
      <Layout variation='under-full-width'>
        <HeaderWithData type='HOME_HEADER' />
        <Section>
          <BannerCarouselWithData
            type='HOME_CAROUSEL'
            eventType={eventType}
          />
          <Partners variation={variation} />
          {firstCollection && (
            <Collection
              key={firstCollection.slug}
              collectionSlug={firstCollection.slug}
              shuffle={true}
            />
          )}
        </Section>
        <FeaturedBrandsPanel
          title='home.featured-brands'
          location='home'
        />
        <Section>
          {remainingCollections &&
            remainingCollections.map((collection: EventCollection) => (
              <Collection
                key={collection.slug}
                collectionSlug={collection.slug}
              />
            ))}
          <BannerAd type='HOME_MIDWAY_AD' />
        </Section>
        <CtaBanner
          title='what-is-click-frenzy.title'
          description='what-is-click-frenzy.description'
          eventType={eventType}
          isCustomBackground={true}
        />
        <FeaturedBrandsPanel
          title='home.top-retailers-event'
          location='home.topRetailers'
        />

        {
          // commented out temporarily for Click Frenzy Thrive Event
          /* <Section>
          <MembershipBenefitsForCurrentEventWithData eventType={eventType} />
        </Section> */
        }
        <CtaBanner
          title='become-member.title'
          description='become-member.description'
          ctaText='become-member.cta'
          ctaUrl='/account?join'
          eventType={eventType}
          isCustomBackground={true}
        />
      </Layout>
    )
  }
}

export default Home
