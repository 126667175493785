import * as React from 'react'
import bind from 'bind-decorator'
import { Modal } from '../../../../../components/modal'
import { CfButton } from '../../../../../components/cf-package'
import { DealTerm, Heading } from '../../../../../components/cf-package'
import { CfDealItem } from '../../../../../models/cfdeal'
import DealCardItem from './DealCardItem'
import './style.scss'
import '../../../../../components/cf-package/CFModal/style.scss'
import { paginateArr, softDealsBy } from '../../../../../utilities/deal.helper'

type Props = {
  className?: string
  id?: string
  data: CfDealItem[]
}

type State = {
  isModalOpen: boolean
  openedDeal?: CfDealItem
  deals: CfDealItem[]
  page: number
  totalPage: number
  sortBy: 'trending' | 'new' | 'ending'
  isSorting: boolean
}
// Trending by Poistion DESC | New by createdDate DESC | Ending by expireDate DESC

const itemPerPage = 12
class DealBrowse extends React.Component<Props> {
  readonly state: State = {
    isModalOpen: false,
    openedDeal: undefined,
    sortBy: 'trending',
    deals: [],
    page: 1,
    totalPage: 0,
    isSorting: false,
  }

  @bind
  openModal(data: unknown) {
    this.setState({
      isModalOpen: true,
      openedDeal: data,
    })
  }

  @bind
  handleModalCancel() {
    this.setState({
      isModalOpen: false,
    })
  }

  @bind
  setSortBy(type: 'trending' | 'new' | 'ending') {
    const _arr = softDealsBy(this.props.data, type)
    const newArr = paginateArr(_arr, 1, itemPerPage)

    this.setState({ isSorting: true })

    // Deplay to show Fade Effecgt
    setTimeout(() => {
      this.setState({
        page: 1,
        deals: newArr,
        sortBy: type,
        isSorting: false,
      })
    }, 200)
  }

  // Detect tab is active or not
  isActiveTab(tabName: 'trending' | 'new' | 'ending'): boolean {
    if (this.state.sortBy === tabName) return true
    else return false
  }

  @bind
  loadMore() {
    if (this.state.totalPage <= 1) return
    const _arr = softDealsBy(this.props.data, this.state.sortBy)
    const newArr = paginateArr(_arr, this.state.page + 1, itemPerPage)

    this.setState({
      page: this.state.page + 1,
      deals: [...this.state.deals, ...newArr],
    })
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    // LOAD DATA to State
    if (this.props.data !== prevProps.data && prevState.deals.length <= 0) {
      if (this.props.data.length > 0) {
        const _arr = softDealsBy(this.props.data, this.state.sortBy)
        const newArr = paginateArr(_arr, 1, itemPerPage)

        this.setState({
          totalPage: Math.ceil(this.props.data.length / itemPerPage),
          page: 1,
          deals: newArr,
          isSorting: false,
        })
      }
    }
  }

  render() {
    const { className = '', id } = this.props
    const { isModalOpen, openedDeal, totalPage, page, deals, isSorting } = this.state

    return (
      <section
        className={`${className} cf-deal-browse`}
        id={id}
      >
        <div className='head-blk cf-flex flex-row align-center justify-between'>
          <Heading className='text-primary ttl-blk head-sm'>
            <span>Browse deals</span>
          </Heading>
          <div className='sm-scroll'>
            <div className='tabs-blk cf-flex flex-row'>
              <CfButton
                onClick={() => this.setSortBy('trending')}
                buttonType={this.isActiveTab('trending') ? 'cfprimary' : undefined}
                size='small'
              >
                Trending
              </CfButton>
              <CfButton
                onClick={() => this.setSortBy('new')}
                buttonType={this.isActiveTab('new') ? 'cfprimary' : undefined}
                size='small'
              >
                New
              </CfButton>
              <CfButton
                onClick={() => this.setSortBy('ending')}
                buttonType={this.isActiveTab('ending') ? 'cfprimary' : undefined}
                size='small'
              >
                Ending soon
              </CfButton>
            </div>
          </div>
        </div>

        <div className={`cf-box ${isSorting ? 'sorting' : ''}`}>
          {deals && (
            <>
              <div className='deals-list'>
                {deals.length > 0 && (
                  <>
                    <div className='cf-flex flex-row flex-wrap gap-x-16 gap-y-32'>
                      {deals.map((item: CfDealItem) => {
                        const { id } = item
                        return (
                          <DealCardItem
                            key={id}
                            id={id}
                            data={item}
                            openModal={() => this.openModal(item)}
                          />
                        )
                      })}
                    </div>
                  </>
                )}
              </div>
              {totalPage > 1 && page < totalPage && (
                <div className='cta-blk cf-flex justify-center'>
                  <CfButton
                    onClick={this.loadMore}
                    buttonType='blue'
                    size='large'
                  >
                    Show me more offers!
                  </CfButton>
                </div>
              )}
            </>
          )}
        </div>

        <Modal
          className='cf-modal-box width-md'
          isOpen={isModalOpen}
          onRequestClose={this.handleModalCancel}
          padding='large'
          size='responsive'
        >
          {openedDeal && <DealTerm data={openedDeal} />}
        </Modal>
      </section>
    )
  }
}

export default DealBrowse
