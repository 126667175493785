import * as React from 'react'
import { CFCountdown, DateButton } from '../../../../../components/cf-package'
import './style/Card.scss'
import Event from '../../../../../models/event'
// import { CalendarIco } from '../../../../../components/cf-package/Svg'
import moment from 'moment'
// import { CalendarButton } from '../../../../../components/calendar-button/CalendarButton'

type Props = {
  className?: string
  id?: string
  data: Event
}

class SecondEvent extends React.Component<Props> {
  render() {
    const { className = '', id, data } = this.props
    const { slug, type, name, description, logo, start } = data

    const to = moment.unix(start)

    return (
      <section
        className={`${className} cf-${type}-card cf-second-card event-card`}
        id={id}
      >
        <div className='inner cf-flex flex-col align-center'>
          <div className='head'>
            <figure className='img'>
              <img
                src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                            wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                data-blink-src={logo}
                alt={name}
              />
            </figure>
            {name && <h5 className='ttl head-sm'>{name}</h5>}
            {description && <div className='desc'>{description}</div>}
            <time
              className='meta-date'
              dateTime={to.format('Y-m-d')}
            >
              {to.format('h:mmA ddd, MMM DD YYYY')}
            </time>
          </div>
          <CFCountdown
            to={start}
            variant='tiny'
          />
          <div className='cta-blk'>
            <DateButton
              id={`countdownCard-${slug}`}
              utmCampaign='savethedate'
            >
              <span>Get Notified</span>
              {/* <CalendarIco /> */}
            </DateButton>
          </div>
        </div>
      </section>
    )
  }
}

export default SecondEvent
