import * as React from 'react'
import './BrandDetailAdditional.scss'
import Brand from '../../models/brand'
import Option from '../../models/option'
import BrandDetailAdditionalSummary from './BrandDetailAdditionalSummary'
import { DictionaryValue } from '../../components/dictionary-value'
import { Grid } from '../../components/normal-grid'
import { getResizedImageFromCDN, brandLogoSize, urlPatternReg } from '../../utilities'
import EventType from '../../models/eventType'

export type Props = {
  brand: Brand | undefined
  payments: Option[] | undefined
  deliveries: Option[] | undefined
  rewardsCashbacks: Option[] | undefined
  eventType?: EventType | null
}

class BrandAdditional extends React.Component<Props> {
  render() {
    const { brand, eventType } = this.props
    if (brand === undefined) {
      return null
    }

    let paymentOptionContent = ''

    const titlePayment = <DictionaryValue token='brand.detail.title.payment' />
    const titleRefundReturnsPolicy = <DictionaryValue token='brand.detail.title.refund.returns.policy' />
    const titleTermsConditions = <DictionaryValue token='brand.detail.title.terms.conditions' />
    const paymentOptionList = this.props.payments
      ?.sort((a: Option, b: Option) => a.slug.localeCompare(b.slug))
      ?.map(
        (paymentOption: Option) =>
          `<li>${paymentOption.name.charAt(0).toUpperCase() + paymentOption.name.slice(1)}</li>`
      )

    if (paymentOptionList && paymentOptionList.length > 0) {
      paymentOptionContent += `<ul class='payment-options'>${paymentOptionList.join('')}</ul>`
    }

    // test if brand paymentUrl is a domain without testing http or https
    const isPaymentUrlDomain = brand.paymentUrl && urlPatternReg.test(brand.paymentUrl)
    if (brand.paymentUrl && isPaymentUrlDomain) {
      paymentOptionContent += `<a href="${brand.paymentUrl}">${brand.paymentUrl}</a>`
    }

    return (
      <div className='brand-detail-additional'>
        <Grid className='brand-detail-additional__additional-grid'>
          <BrandDetailAdditionalSummary
            className='brand-detail-additional__payment'
            eventType={eventType}
            title={titlePayment}
            content={paymentOptionContent}
            brandName={brand.name}
            brandLogo={getResizedImageFromCDN(brand.logo, brand.logoIsBitmap, brandLogoSize.detailModal)}
          />
          <BrandDetailAdditionalSummary
            className='brand-detail-additional__termsconditions'
            eventType={eventType}
            title={titleTermsConditions}
            content={
              brand.termsConditions && urlPatternReg.test(brand.termsConditions)
                ? `<a href="${brand.tandcClickmeterLinkUrl}">${brand.termsConditions}</a>`
                : brand.termsConditions
            }
            brandName={brand.name}
            brandLogo={getResizedImageFromCDN(brand.logo, brand.logoIsBitmap, brandLogoSize.detailModal)}
          />
          <BrandDetailAdditionalSummary
            className='brand-detail-additional__refundreturnpolicy'
            eventType={eventType}
            title={titleRefundReturnsPolicy}
            content={
              brand.refundReturnsPolicy && urlPatternReg.test(brand.refundReturnsPolicy)
                ? `<a href="${brand.refundReturnsPolicy}">${brand.refundReturnsPolicy}</a>`
                : brand.refundReturnsPolicy
                ? brand.refundReturnsPolicy
                : ''
            }
            brandName={brand.name}
            brandLogo={getResizedImageFromCDN(brand.logo, brand.logoIsBitmap, brandLogoSize.detailModal)}
          />
        </Grid>
      </div>
    )
  }
}

export default BrandAdditional
